@import "~materialize-css/dist/css/materialize.min.css";

.container {
    width: 100%;
    max-width: 100%;
}



/* классы для партнера */
/* .navbar-style {
    background-color: chartreuse;
}

.text-style a{
    color: black
}

.btn-style


*/
