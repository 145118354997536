.main {
  /*   display: flex;
    flex-direction: row; */
}
.main__left {
  height: 90vh;
  /* background-color: antiquewhite; */
}
.main__right {
  height: 90vh;
  /* background-color: aquamarine; */
}

.logo {
  max-width: 85px;
  max-height: 37px;
  margin-right: 2.5rem;
}
.year-box {
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  border-color: lightgray;
}
.year-box-double {
  border: 1px solid;
  border-radius: 5px;
  margin: 5px 5px 0 5px;
}
.year-box-double span {
  width: 100%;
}
.double {
  margin-bottom: 0px;
}
.year-box:hover {
  color: gray;
  /* box-shadow: 2px 2px 2px 2px black; */
}
.year-box span {
  cursor: pointer;
  width: 100%;
  color: black;
}
.year-col {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.bonus-step {
  max-width: 200px;
  padding: 10px;
}
.avatar {
  max-width: 90px;
  padding: 10px;
}

.main-card {
  padding: 2rem;
  min-height: 570px !important;
  max-width: 1000px;
  margin: 50px auto 0 auto;
  display: flex;
}

.right-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 200px;
  background: #f0f0f0;
  border-radius: 5px;
}

@media screen and (max-width: 500px) {
  .main-card {
    padding: 2rem;
    max-width: 1000px;
    margin: 50px auto 0 auto;
    display: block;
  }
  .bonus-step {
    width: 100%;
    padding: 10px;
    max-width: 100%;
  }
  .avatar {
    max-width: 90px;
    padding: 10px;
  }
  .right-sidebar {
    max-width: 100%;
    flex-direction: row;
  } 
}


.left-sidebar {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.check {
  margin: 0 -9px -9px 9px;
  padding-right: -10px;
}

.control-label {
  font-size: 10px !important;
}

.card-action {
  padding: 0px 10px !important;
}

.card-content {
  padding: 0px 10px !important;
}
.bonus {
  width: 100%;
}